import { BATCH_STATES, createBatchItem } from "@rpldy/shared";
import { DEFAULT_FILTER } from "./defaults";
import { getIsFileList } from "./utils";
let bCounter = 0;

const processFiles = (batchId, files, isPending, fileFilter) => Array.prototype //$FlowExpectedError[method-unbinding] flow 0.153 !!!
.filter.call(files, fileFilter || DEFAULT_FILTER).map(f => createBatchItem(f, batchId, isPending));

export default ((files, uploaderId, options) => {
  bCounter += 1;
  const id = `batch-${bCounter}`;
  const isFileList = getIsFileList(files);
  files = Array.isArray(files) || isFileList ? files : [files];
  const isPending = !options.autoUpload;
  const items = processFiles(id, files, isPending, options.fileFilter);
  return {
    id,
    uploaderId,
    items,
    state: isPending ? BATCH_STATES.PENDING : BATCH_STATES.ADDED,
    completed: 0,
    loaded: 0,
    orgItemCount: items.length
  };
});