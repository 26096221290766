import { useCallback, useRef } from "react";
import { useUploady } from "@rpldy/shared-ui";

const usePasteHandler = (uploadOptions, onPasteUpload) => {
  const {
    upload
  } = useUploady(); //using ref so paste callback can stay memoized

  const uploadOptionsRef = useRef();
  uploadOptionsRef.current = uploadOptions;
  return useCallback(e => {
    var _e$clipboardData;

    const files = (_e$clipboardData = e.clipboardData) === null || _e$clipboardData === void 0 ? void 0 : _e$clipboardData.files;

    if (files !== null && files !== void 0 && files.length) {
      upload(files, uploadOptionsRef.current);
      onPasteUpload === null || onPasteUpload === void 0 ? void 0 : onPasteUpload({
        count: files.length
      });
    }
  }, [upload, uploadOptionsRef, onPasteUpload]);
};

export default usePasteHandler;