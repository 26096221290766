import { isFunction } from "@rpldy/shared";

/**
 * mimics FormData.set() when its not available (react-native)
 * in case FormData.delete() isnt available, will append only (unlike set)
 */
const addToFormData = (fd, name, ...rest) => {
  //rest = [value, fileName = undefined]
  if ("set" in fd) {
    // $FlowFixMe - ignore flow for not allowing FileLike here
    fd.set(name, ...rest);
  } else {
    if ("delete" in fd) {
      fd.delete(name);
    } // $FlowFixMe - ignore flow for not allowing FileLike here


    fd.append(name, ...rest);
  }
};

const getFormFileField = (fd, items, options) => {
  const single = items.length === 1;
  items.forEach((item, i) => {
    const name = single ? options.paramName : isFunction(options.formatGroupParamName) ? options.formatGroupParamName(i, options.paramName) : `${options.paramName}[${i}]`;

    if (item.file) {
      addToFormData(fd, name, item.file, item.file.name);
    } else if (item.url) {
      addToFormData(fd, name, item.url);
    }
  });
};

const prepareFormData = (items, options) => {
  const fd = new FormData();

  if (options.params) {
    Object.entries(options.params).forEach(([key, val]) => addToFormData(fd, key, val));
  }

  getFormFileField(fd, items, options);
  return fd;
};

export default prepareFormData;