import { logger, FILE_STATES } from "@rpldy/shared";
import { MOCK_SENDER_TYPE } from "./consts";
import { MOCK_DEFAULTS } from "./defaults";

const createRequest = (options, items) => {
  const start = performance.now();
  const progressEventsData = [];
  let isCancelled = false,
      isDone = false,
      progressCallback = null,
      progressTimeouts = null,
      cancelRequest = null;

  const clearTimeouts = () => {
    if (progressTimeouts) {
      progressTimeouts.forEach(handle => {
        clearTimeout(handle);
      });
      progressTimeouts = null;
    }
  };

  const abort = () => {
    isCancelled = true;
    isDone = true;

    if (cancelRequest) {
      cancelRequest();
    }

    clearTimeouts();
    return true;
  };

  const p = new Promise((resolve, reject) => {
    cancelRequest = reject;
    setTimeout(() => {
      isDone = true;
      resolve({
        options,
        time: performance.now() - start,
        progressEvents: progressEventsData
      });
      clearTimeouts();
    }, options.delay || 0);
  });

  if (options.progressIntervals) {
    progressTimeouts = options.progressIntervals.map(amount => {
      const perc = amount / 100;
      const ms = (options.delay || 0) * perc;
      return setTimeout(() => {
        if (!isCancelled && !isDone && progressCallback) {
          const event = {
            total: options.fileSize || 0,
            loaded: (options.fileSize || 0) * perc
          };
          progressEventsData.push(event);
          progressCallback(event, items);
        }
      }, ms);
    });
  }

  return {
    //$FlowExpectedError[method-unbinding] flow 0.153 !!!
    then: p.then.bind(p),
    abort,
    onProgress: cb => {
      progressCallback = cb;
    }
  };
};

const processResponse = (request, options, sendOptions) => {
  return request.then(mockResponse => {
    var _sendOptions$formatSe;

    logger.debugLog("uploady.mockSender: mock request finished successfully");
    const mockResponseData = {
      sendOptions,
      mock: true,
      success: true
    };
    const mockHeaders = {
      "x-request-type": "react-uploady.mockSender"
    };
    const mockStatus = options.responseStatus || 200;
    return {
      status: mockStatus,
      state: FILE_STATES.FINISHED,
      response: { ...mockResponse,
        headers: mockHeaders,
        data: options.response || ((_sendOptions$formatSe = sendOptions.formatServerResponse) === null || _sendOptions$formatSe === void 0 ? void 0 : _sendOptions$formatSe.call(sendOptions, JSON.stringify(mockResponseData), mockStatus, mockHeaders)) || mockResponseData
      }
    };
  }).catch(err => {
    logger.debugLog("uploady.mockSender: mock request was aborted", err);
    return {
      status: 0,
      state: FILE_STATES.ABORTED,
      response: "abort"
    };
  });
};

export default (options => {
  let mockOptions = { ...MOCK_DEFAULTS,
    ...options
  };

  const update = updated => {
    mockOptions = { ...mockOptions,
      ...updated
    };
  };

  const send = (items, url, sendOptions, onProgress) => {
    logger.debugLog("uploady.mockSender: about to make a mock request for items: ", items);
    const request = createRequest(mockOptions, items);
    request.onProgress(onProgress);
    return {
      request: processResponse(request, mockOptions, sendOptions),
      abort: request.abort,
      senderType: MOCK_SENDER_TYPE
    };
  };

  return {
    send,
    update
  };
});